import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import CompactLayout from 'src/layouts/Mainsite';
// import Home from 'src/pages/mainSite/Home';

// ----------------------------------------------------------------------

const Page404 = lazy(() => import('src/pages/404'));
const Page403 = lazy(() => import('src/pages/403'));
const Page500 = lazy(() => import('src/pages/500'));
const Home = lazy(() => import('src/pages/mainSite/Home'));
const AboutUs = lazy(() => import('src/pages/mainSite/AboutUs'));
const Legal = lazy(() => import('src/pages/mainSite/Legal'));
const Banker = lazy(() => import('src/pages/mainSite/Banker'));
const Gallery = lazy(() => import('src/pages/mainSite/Gallery'));
const ContactUs = lazy(() => import('src/pages/mainSite/ContactUs'));

const Products = lazy(() => import('src/pages/mainSite/product/Products'));
// const ProductsList = lazy(() => import('src/pages/mainSite/product/list'));
const Product = lazy(() => import('src/pages/mainSite/product/details'));
const Cart = lazy(() => import('src/pages/mainSite/product/checkout'));

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/register'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    path: '/',
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),

    children: [
      // export const mainRoutes = [
      {
        path: '/',
        element: <Home />,
        index: true,
      },
      {
        path: 'about_us',
        element: <AboutUs />,
      },
      // {
      //   path: 'products',
      //   element: <Products />,
      // },
      {
        path: 'legal',
        element: <Legal />,
      },
      {
        path: 'banker',
        element: <Banker />,
      },
      {
        path: 'gallery',
        element: <Gallery />,
      },
      {
        path: 'contactus',
        element: <ContactUs />,
      },

      {
        path: 'products',
        element: <Products />,
      },
      {
        path: 'products/:id',
        element: <Product />,
      },
      {
        path: 'cart',
        element: <Cart />,
      },

      // {
      //   path: 'login',
      //   element: <JwtLoginPage />,
      // },

      // {
      //   path: 'register',
      //   element: <JwtRegisterPage />,
      // },
      // { path: 'coming-soon', element: <ComingSoonPage /> },
      // { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
