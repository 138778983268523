import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/Admindashboard';

import { LoadingScreen } from 'src/components/loading-screen';
const ProductInfo = lazy(() => import('src/pages/adminDashboard/Products/singleProduct'));

// ----------------------------------------------------------------------

const DashboardPage = lazy(() => import('src/pages/adminDashboard/dashboard'));
const MembersPage = lazy(() => import('src/pages/adminDashboard/members/members'));
const InventoryPage = lazy(() => import('src/pages/adminDashboard/inventory'));
const FundsPage = lazy(() => import('src/pages/adminDashboard/funds'));
const Kyc = lazy(() => import('src/pages/adminDashboard/members/Kyc'));

const ProductsPage = lazy(() => import('src/pages/adminDashboard/Products/products'));
const ProductMasters = lazy(() => import('src/pages/adminDashboard/Products/productMasters'));

const OrdersPage = lazy(() => import('src/pages/adminDashboard/orders'));
const PayoutsPage = lazy(() => import('src/pages/adminDashboard/payouts'));
const GalleryPage = lazy(() => import('src/pages/adminDashboard/gallery'));
const NotificationsPage = lazy(() => import('src/pages/adminDashboard/notifications'));
const FrontManagementPage = lazy(() => import('src/pages/adminDashboard/frontmanagement'));

// ----------------------------------------------------------------------

// Admin dashboard routes

export const adminDshboardRoutes = [
  {
    path: 'dashboard',
    element: (
      // <AuthGuard>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
      // </AuthGuard>
    ),
    children: [
      { element: <DashboardPage />, index: true },
      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'funds', element: <FundsPage /> },
      // { path: 'members', element: <MembersPage /> },
      {
        path: 'members',
        children: [
          { element: <MembersPage />, index: true },
          { path: 'kyc', element: <Kyc /> },
        ],
      },
      {
        path: 'products',
        children: [
          { element: <ProductsPage />, index: true },
          { path: 'masters', element: <ProductMasters /> },
        ],
      },
      { path: 'productInfo/:id', element: <ProductInfo /> },
      { path: 'inventory', element: <InventoryPage /> },
      { path: 'orders', element: <OrdersPage /> },
      { path: 'payouts', element: <PayoutsPage /> },
      { path: 'gallery', element: <GalleryPage /> },
      { path: 'notifications', element: <NotificationsPage /> },
      { path: 'website_management', element: <FrontManagementPage /> },
      {
        path: 'group',
        children: [
          { element: <DashboardPage />, index: true },
          { path: 'five', element: <DashboardPage /> },
          { path: 'six', element: <DashboardPage /> },
        ],
      },
    ],
  },
];
