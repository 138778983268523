import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/Admindashboard';

import { LoadingScreen } from 'src/components/loading-screen';
import MemberDashboardLayout from 'src/layouts/memberPanel';

// ----------------------------------------------------------------------
 
 

const Dashboard = lazy(() => import('src/pages/userDashboard/Dashboard'));

const TermsConditions = lazy(() => import('src/pages/userDashboard/policies/TermsConditions'));
const PromotionsDistributers = lazy(
  () => import('src/pages/userDashboard/policies/PromotionsDistributers')
);
const Opportunity = lazy(() => import('src/pages/userDashboard/policies/Opportunity'));
const PrivacyPolicy = lazy(() => import('src/pages/userDashboard/policies/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('src/pages/userDashboard/policies/RefundPolicy'));

const Profile = lazy(() => import('src/pages/userDashboard/profile/Profile'));
const Kyc = lazy(() => import('src/pages/userDashboard/profile/Kyc'));
const AddBank = lazy(() => import('src/pages/userDashboard/profile/AddBank'));
const ChangePassword = lazy(() => import('src/pages/userDashboard/profile/ChangePassword'));

const UserRaisedTickets = lazy(() => import('src/pages/userDashboard/UserRaisedTickets'));
const RaiseTicket = lazy(() => import('src/pages/userDashboard/RaiseTicket'));
const Payout = lazy(() => import('src/pages/userDashboard/Payout'));
const InvoiceList = lazy(() => import('src/pages/userDashboard/InvoiceList'));
const TransactionReport = lazy(() => import('src/pages/userDashboard/TransactionReport'));
const AvailablePins = lazy(() => import('src/pages/userDashboard/AvailablePins'));
const UsedPins = lazy(() => import('src/pages/userDashboard/UsedPins'));
const TransferPins = lazy(() => import('src/pages/userDashboard/TransferPins'));
const Register = lazy(() => import('src/pages/userDashboard/Register'));
const WelcomeLetter = lazy(() => import('src/pages/userDashboard/WelcomeLetter'));
const IDCard = lazy(() => import('src/pages/userDashboard/IDCard'));
const MyDirects = lazy(() => import('src/pages/userDashboard/MyDirects'));
const TreeDetails = lazy(() => import('src/pages/userDashboard/TreeDetails'));
const GenerationTree = lazy(() => import('src/pages/userDashboard/GenerationTree'));
// import ManageFunds from 'src/pages/userDashboard/fundsManagement/manageFunds';
const ManageFunds = lazy(() => import('src/pages/userDashboard/fundsManagement/ManageFunds'));
const AddFund = lazy(() => import('src/pages/userDashboard/fundsManagement/AddFund'));
const RequestFund = lazy(() => import('src/pages/userDashboard/fundsManagement/RequestFund'));
const FundHistory = lazy(() => import('src/pages/userDashboard/fundsManagement/FundHistory'));

// ----------------------------------------------------------------------

// Admin dashboard routes

export const memberDashboardRoutes = [
  {
    path: 'member',
    element: (
      // <AuthGuard>
      <MemberDashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </MemberDashboardLayout>
      // </AuthGuard>
    ),
    children: [
      { element: <Dashboard />, index: true },

      {
        path: 'policy',
        children: [
          { element: <Opportunity />, index: true },
          { path: 'privacyPolicy', element: <PrivacyPolicy /> },
          { path: 'refundPolicy', element: <RefundPolicy /> },
          { path: 'bussinessPlan', element: <Opportunity /> },
          { path: 'promotions', element: <PromotionsDistributers /> },
          { path: 'termsconditions', element: <TermsConditions /> },
        ],
      },

      { path: 'newRegistration', element: <Register /> },

      {
        path: 'profile',
        children: [
          { element: <Profile />, index: true },
          { path: 'profile', element: <Profile /> },
          { path: 'changePassword', element: <ChangePassword /> },
          { path: 'kyc', element: <Kyc /> },
          { path: 'banks', element: <AddBank /> },
          { path: 'welcomeLetter', element: <WelcomeLetter /> },
          { path: 'idCard', element: <IDCard /> },
        ],
      },

      {
        path: 'funds',
        children: [
          { element: <ManageFunds />, index: true },
          { path: 'fundManagement', element: <ManageFunds /> },
          { path: 'addFund', element: <AddFund /> },
          { path: 'requestFund', element: <RequestFund /> },
          { path: 'fundHistory', element: <FundHistory /> },
        ],
      },

      {
        path: 'myNetwork',
        children: [
          { element: <MyDirects />, index: true },
          { path: 'myDirects', element: <MyDirects /> },
          { path: 'treeDetails', element: <TreeDetails /> },
          { path: 'generationTree', element: <GenerationTree /> },
        ],
      },

      // Parent mynetwork Path
      { path: 'myNetwork', element: <MyDirects /> },
      // Child myNetwork Path
      { path: 'myDirects', element: <MyDirects /> },
      { path: 'treeDetails', element: <TreeDetails /> },
      { path: 'generationTree', element: <GenerationTree /> },

      // parent route
      { path: 'licenceAllotment', element: <AvailablePins /> },
      // child routes only of needed
      { path: 'availablePins', element: <AvailablePins /> },
      { path: 'usedPins', element: <UsedPins /> },
      { path: 'transferPins', element: <TransferPins /> },

      { path: 'myIncome', element: <TransactionReport /> },

      { path: 'orderList', element: <InvoiceList /> },

      { path: 'withdrawl', element: <Payout /> },

      { path: 'promotionsDistributers', element: <PromotionsDistributers /> },

      { path: 'opportunity', element: <Opportunity /> },

      {
        path: 'tickets',
        children: [
          { element: <UserRaisedTickets />, index: true },
          { path: 'raisedTickets', element: <UserRaisedTickets /> },
          { path: 'raiseTicket', element: <RaiseTicket /> },
          { path: 'ticketCompleted', element: <RaiseTicket /> },
        ],
      },
    ],
  },
];
