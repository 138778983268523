import PropTypes from 'prop-types';

// import Stack from '@mui/material/Stack';
// import Container from '@mui/material/Container';

// import Header from '../common/header-simple';
import { Box } from '@mui/material';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import Menu from './Navbar/Menu';

// ----------------------------------------------------------------------

export default function CompactLayout({ children }) {
  return (
    <>
      <Navbar />
      <Menu />
      <Box>{children}</Box>
      <Footer />
    </>
  );
}

CompactLayout.propTypes = {
  children: PropTypes.node,
};
