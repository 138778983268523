import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'DS Founding',
        items: [
          { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
          // {
          //   title: 'Members',
          //   path: paths.dashboard.members,
          //   icon: <Iconify icon="solar:user-bold" width={22} />,
          // },
          {
            title: 'Members',
            path: paths.dashboard.members.root,
            icon: <Iconify icon="solar:user-bold" width={22} />,
            children: [
              { title: 'Members List', path: paths.dashboard.members.root },
              { title: 'Kyc List', path: paths.dashboard.members.kycList },
            ],
          },
          {
            title: 'Funds Management',
            path: paths.dashboard.funds,
            icon: <Iconify icon="solar:card-bold" width={22} />,
          },
          {
            title: 'Products Management',
            path: paths.dashboard.products.root,
            icon: <Iconify icon="dashicons:products" width={22} />,
            children: [
              { title: 'Product Catalog', path: paths.dashboard.products.productCatalog },
              { title: 'Product Masters', path: paths.dashboard.products.productMasters },
            ],
          },
          {
            title: 'Inventory Managment',
            path: paths.dashboard.inventory,
            icon: <Iconify icon="mingcute:inventory-fill" width={22} />,
          },
          {
            title: 'Orders',
            path: paths.dashboard.orders,
            icon: <Iconify icon="material-symbols:orders-rounded" width={22} />,
          },
          {
            title: 'Payouts',
            path: paths.dashboard.payouts,
            icon: <Iconify icon="mingcute:card-pay-fill" width={22} />,
          },
          {
            title: 'Gallery',
            path: paths.dashboard.gallery,
            icon: <Iconify icon="solar:gallery-bold" width={22} />,
          },
          {
            title: 'Website Management',
            path: paths.dashboard.website_management,
            icon: <Iconify icon="ant-design:setting-filled" width={22} />,
          },
          {
            title: 'Notifications',
            path: paths.dashboard.notifications,
            icon: <Iconify icon="bxs:bell" width={22} />,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      // {
      //   subheader: 'management',
      //   items: [
      //     {
      //       title: 'user',
      //       path: paths.dashboard.group.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: 'Orders', path: paths.dashboard.group.root },
      //         { title: 'Payouts', path: paths.dashboard.group.five },
      //         { title: 'Gallery', path: paths.dashboard.group.six },
      //         { title: 'Website Setting', path: paths.dashboard.group.six },
      //       ],
      //     },
      //   ],
      // },
    ],
    []
  );

  return data;
}
