import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { LANDINGPAGE, PATH_AFTER_LOGIN } from 'src/config-global';

// import { MainLayout } from 'src/layouts/main';

import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { adminDshboardRoutes } from './admindashboard';
import { memberDashboardRoutes } from './userDashboardRoutes';
import { AuthContext } from 'src/utils/context/AuthContext';

// ----------------------------------------------------------------------

export default function Router() {
  const { AppFunctions, appStates } = React.useContext(AuthContext);

  const validUser = appStates.authenticated;
  const loggedInUser = appStates.authenticatedUserType;

  // const validUser = true;
  // const loggedInUser = 'Admin';

  // console.log(
  //   'Authenticated or not ln.23 index.jsx routes',
  //   appStates.authenticated,
  //   appStates.authenticatedUserType
  // );

  if (validUser && loggedInUser == 'Admin') {
    var AllRoutes = '/dashboard';
  } else if (validUser && loggedInUser == 'Member') {
    var AllRoutes = '/member';
  } else {
    var AllRoutes = '/auth/login';
  }

  return useRoutes([
    // Auth routes
    ...(!validUser ? authRoutes : []),
    // ...authRoutes,

    // Admin Dashboard routes
    ...(validUser && loggedInUser == 'Admin' ? adminDshboardRoutes : []),

    // User Dashboard Routes
    ...(validUser && loggedInUser === 'Member' ? memberDashboardRoutes : []),

    // Main routes means no matter if user is logged in or not they will show
    ...mainRoutes,

    // No match 404
    // { path: '*', element: <Navigate to="/404" replace /> },
    { path: '*', element: <Navigate to={AllRoutes} replace /> },
  ]);
}
