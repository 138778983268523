import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// console.log(process.env.REACT_APP_API_URL);
// process.env.NODE_ENV === "development"
//   ? "http://localhost:5004/"
//   : "https://newserver.mahadevagroup.in/";
// process.env.NODE_ENV === "development"
//   ? "https://newserver.mahadevagroup.in/"
//   : "https://newserver.mahadevagroup.in/";

const send_post = async (url, body = {}) => {
  var token;
  token = localStorage.getItem("token");
  let localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);
  var userId;

  if (localCheck === null) {

  } else {
    var { userId } = localCheck;
  }

  return axios.post(url, body, {
    headers: {
      userId: userId,
      Authorization: token,
    },
  });
};

const send_put = async (url, body = {}) => {
  var token;
  token = localStorage.getItem("token");
  let localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);
  var userId;
  if (localCheck === null) {
  } else {
    var { userId } = localCheck;
  }
  return axios.put(url, body, {
    headers: {
      userId: userId,
      Authorization: token,
    },
  });
};

const send_patch = async (url, body = {}) => {
  var token;
  token = localStorage.getItem("token");
  var localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);
  var userId;
  if (localCheck === null) {
  } else {
    var { userId } = localCheck;
  }
  return axios.patch(url, body, {
    headers: {
      userId: userId,
      Authorization: token,
    },
  });
};



const send_patch_func = async (url = "", body = {}) => {
  var token;
  token = localStorage.getItem("token");
  var localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);
  var userId;
  if (localCheck === null) {
  } else {
    ({ userId } = localCheck);
  }
  return axios.patch(url, body, {
    headers: {
      userId: userId,
      Authorization: token,
    },
  });
};



const send_get = async (url) => {
  var token;
  token = localStorage.getItem("token");
  var localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);

  var userId;

  if (localCheck === null) {
  } else {
    var { userId } = localCheck;
  }
  return axios.get(url, {
    headers: {
      userId: userId,
      Authorization: token,
    },
  });
};

const send_delete = async (url) => {
  var token;
  token = localStorage.getItem("token");
  var localCheck = localStorage.getItem("userData");
  localCheck = JSON.parse(localCheck);

  var userId;

  if (localCheck === null) {
  } else {
    var { userId } = localCheck;
  }
  return axios.delete(url, {
    headers: {
      userId: userId,
      Authorization: token,
    },
  });
};

// const send_delete_element = async (url) => {
//   const localCheck = localStorage.getItem("userData");
//   localCheck = JSON.parse(localCheck);
//   var userId;
//   var token;
//   if (localCheck === null) {
//   } else {
//     var { userId, token } = localCheck;
//   }
//   return axios.delete(url, {
//     headers: {
//       userId: userId,
//       Authorization: token,
//     },
//   });
// };

const logout = () => {
  console.log("logout");
  localStorage.clear();
};

export { send_post, send_get, send_put, send_delete, logout, send_patch, send_patch_func };
