export const imageUrlHelper = (ImageUrl) => {
    // console.log('ImageUrl',ImageUrl)
    let url = 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/832px-No-Image-Placeholder.svg.png';

if(!ImageUrl){
    // console.log('No Image Url' , ImageUrl)
    return url;
}else{

    if(ImageUrl.startsWith('http')){
        url = ImageUrl
    }else{
        url = process.env.REACT_APP_ASSETS_API+ImageUrl;
    }

    return url
}
}