import React, { useEffect } from 'react';
import mail from '../assest/mail.svg';
import whatsapp from '../assest/message-circle.svg';
import instagram from '../assest/Instgram.svg';
import facebook from '../assest/Twitter.svg';
import logo from '../assest/logo.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Badge, Box } from '@mui/material';
import { AuthContext } from 'src/utils/context/AuthContext';
import { imageUrlHelper } from 'src/utils/helpers/ImageUrlHelper';

function Navbar() {
  return (
    <div>
      {/* Top */}
      <Box
        style={{
          padding: '12px ',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid #ECECEC',
        }}
      >
        {/* Left */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: 'center',
            gap: {
              xs: '10px',
              md: '16px',
            },
          }}
        >
          <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <img style={{ height: '14px', width: '14px' }} src={mail} />
            <span
              className="clickable"
              style={{
                color: '#131212',
                fontSize: '12px',
                fontWeight: '400',
                // lineHeight: "normal",
                display: 'flex',
                alignItems: 'center',
              }}
            >
              contact@dsfounding.in
            </span>
          </div>

            <a style={{
              textDecoration: 'none',
            }} href="https://api.whatsapp.com/send?phone=917404309292&text=Hi%20%2C%20I%27m%20here%20from%20dsfounding" target="_blank" rel="noopener noreferrer">
          <div className="clickable" style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>

            {/* a='https://api.whatsapp.com/send?phone=917404309292&text=Hi%20%2C%20I%27m%20here%20from%20dsfounding' */}

            <WhatsAppIcon 
             style={{ height: '14px', width: '14px', color: '#0DC214' }} />
            {/* <img style={{ height: "14px", width: "14px" }} src={whatsapp} /> */}
            <span
              style={{
                color: '#131212',
                fontSize: '12px',
                fontWeight: '400',
                // lineHeight: "normal",
                display: 'flex',
                alignItems: 'center',
              }}
              >
              7404309292
            </span>
          </div>
              </a>
        </Box>
        {/* Right */}
        <div
          className="clickable"
          style={{ display: 'flex', gap: '12px', justifyContent: 'center', alignItems: 'center' }}
        >
          <img src={instagram} height={20} width={20} />
          <img src={facebook} height={20} width={20} />
        </div>
      </Box>

      {/* Bottom */}
      <Box
        sx={{
          padding: '20px 80px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          gap: {
            xs: '16px',
            md: '20px',
          },
        }}
      >
        {/* Logo */}
        <div>
          <img className="clickable" src={logo} />
        </div>
        {/* Search Bar */}
        <OutlinedInput
          sx={{
            width: { sx: 'auto', md: '630px' },
            backgroundColor: '#FAFAFA',
            border: '1px solid #EBEBEB',
            borderRadius: '4px',
          }}
          size="small"
          placeholder="Search For Products"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        {/* Buttons */}
        <NavButtons />
      </Box>
    </div>
  );
}

export default Navbar;

export const NavButtons = ({
  smallScreen = 'none',
  bigscreen = 'flex',
  fontSize = '14px',
  fontColor = '#a5548c',
  iconSize = '24px',
  iconColor = '#a5548c',
  itemsGap = '24px',
  ...props
}) => {
  // console.log('NavButtons', props);

  const { AppFunctions, appStates } = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    AppFunctions.getCartData();
  }, [])
  return (
    <Box
      sx={{
        display: {
          xs: smallScreen,
          md: bigscreen,
        },
        gap: itemsGap,
        ...props,
      }}
    >
      {/* Wishlist */}
      <div
      onClick={()=>console.log(appStates)}
        className="clickable"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FavoriteBorderIcon style={{ width: iconSize, height: iconSize, color: iconColor }} />
        <div style={{ color: fontColor, fontSize: fontSize, fontWeight: '400' }}>Wishlist</div>
      </div>
      {/* Cart */}
      <div
      onClick={()=>navigate('cart')}
        className="clickable"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Badge badgeContent={Array.isArray(appStates.cartContents)  && appStates.cartContents.length > 0 ? appStates.cartContents[0]?.products?.length : 0 || 0 } color="primary">
        <ShoppingCartOutlinedIcon style={{ width: iconSize, height: iconSize, color: iconColor }} />
        </Badge>
        <div style={{ color: fontColor, fontSize: fontSize, fontWeight: '400' }}>Cart</div>
      </div>
      {/* Login/Register */}



<div className="clickable">
        <Link
          to="auth/login"
          sx={{
            display: {
              sm: 'none',
              md: 'flex',
            },
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            margin: 0,
            padding: 0,
          }}
        >
          { appStates.authenticatedUser == true ?
          <>
        
          <Avatar style={{ width: iconSize, height: iconSize, color: iconColor , backgroundColor :'red'}}  src={imageUrlHelper(appStates.userInfo.profilePic)} /> 
          <div
          style={{
            color: fontColor,
            fontSize: fontSize,
            fontWeight: '400',
          }}
          >
         {appStates.userInfo.userName}
        </div>
          </>
          :
          <>
          <PersonOutlineOutlinedIcon
            style={{ width: iconSize, height: iconSize, color: iconColor }}
            />
              <div
            style={{
              color: fontColor,
              fontSize: fontSize,
              fontWeight: '400',
            }}
          >
            Login/Register
          </div>
            </>
}
        
        </Link>
      </div>
    


      



    </Box>
  );
};
