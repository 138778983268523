export const Auth = {
    userLogin: "/login/userLogin",
    userRegister: "/register/userRegister",
    getSponsorDetails: "/register/getSponsorDetails",
    verifyToken : "/login/verifyToken",

}

export const AdminApis = {
    //member routes
    getAllMembers: "/members/getMembers",
    EditMember : '',

    // policies route 
    getPoliciesList: "/policies/getPoliciesList",
    updatePolicy: "/policies/updatePolicy",

    // product masters routes


    getProductmasters: "/productmaster/getProductmasters",
    insertProductmaster: "/productmaster/insertProductmaster",
    deleteSingleProductmaster: "/productmaster/deleteSingleProductmaster",
    updateProductmaster: "/productmaster/updateProductmaster",

    // products routes
    getProducts: "/product/getProducts",
    getSingleProduct: "/product/getSingleProduct/",
    insertProduct: "/product/insertProduct",
    deleteSingleProduct: "/product/deleteSingleProduct",
    trashSingleProduct: "/product/trashSingleProduct",
    updateProduct: "/product/updateProduct",
    deleteProductImage: '/product/deleteImage',
    deleteFeaturedImage: '/product/deleteFeaturedImage',


    //deleteSomething

    deleteSomething: "/delete/deleteSomething",

    // gallery
    getGallery: "/gallery/getGallery",
    uploadgallery: "/gallery/uploadgallery",
    deleteImage: "/gallery/deleteImage",


    // kycs List

    getKycList : "kyc/getAllKycs",
    approveKyc : "kyc/approveDoc/",
    rejectKyc : "kyc/rejectDoc/",


    // manage funds 
    getFunds: "/funds/getFunds",
    manageFunds :'funds/managefunds',
    approveFunds : "funds/approveFunds", // done
    rejectFunds : "funds/rejectFunds", // done


    // remaining

    getAdminDashboardData : 'data/getAdminDashboardData',
    
    
    AddInventoryStock : 'inventory/AddInventoryStock',
    getInventoryStock : 'inventory/getInventoryStock',
    AddVendors : 'inventory/AddVendors',
    getVendorsList : 'inventory/getVendorsList',
    deleteVendor : 'inventory/deleteVendor',
    getPurchaseLogs : 'inventory/getPurchaseLogs',
    
    getOrdersList : 'orders/getOrdersList',
    dispatchOrder : 'orders/dispatchOrder',
    
    getPayoutsList : 'payouts/getPayoutsList',

}


export const userApis = {
    updateProfilePic: 'profile/updateprofilePic',
    updateProfile: 'profile/updateprofile',
    getProfile: 'members/getSingleMember/',

    getUserDetails : 'profile/getUserDetails',
    getUserDashboardDetails : 'profile/getUserDashboardData',

    submitKyc: 'kyc/uploadDocument',
    getKyc: 'kyc/getKYCdoc',

    addBank: 'bankData/bankDetails', // add bank data via formdata
    getuserBanks: 'bankData/bankDetails/', // get Single user bank details
    deleteBank: 'bankData/deleteBankDetails/', // delete single user bank details via bank account ObjectId 
    
    getWelcomeCard: '',
    getIdCard: '',

    addFunds: 'funds/addFund',
    fundsHistory: 'funds/getUserFunds',


    myDirects: '',
    myLevelReport: '',
    treeDetails: '',


    myIncome: '',

    myOrders: '',
    
    addWithdrawalRequest: '',
    withdrawlHistory: '',

    raiseTicket: 'supportTicketData/addTicket', // raise support ticket via sending data into formdata
    getTickets: 'supportTicketData/getTickets/', // get Single User Tickets
    getSingleTicketInfo: 'supportTicketData/getTickets/', // get single ticket Info by Sending Ticket Id 
    addTicketMessage: 'supportTicketData/addTicketMessage', // add ticket msg via formdata ( message : attachment )

    updatePassword: 'profile/updatePassword',
    changePassword: 'profile/changePassword',
    changeTransactionPassword: 'profile/changeTransactionPassword',


    addToCart : 'product/addToCart',
    getCartContents:'product/getCartContents',
    updateCart: 'product/updateCart',
}


export const mainSiteApis = {
    "getFeaturedProducts": "/featuredCategory/getfeatureCategoryProducts",
    "getGallery": "/gallery/getGallery",
}

export const logsApi = {
    error: "error",
    senderror: "error/senderror"
}

