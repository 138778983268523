import { Fingerprint } from '@mui/icons-material';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { NavButtons } from './Navbar';
import {
  OtherHousesOutlined,
  GroupsOutlined,
  HowToRegOutlined,
  Inventory2Outlined,
  PolicyOutlined,
  CollectionsOutlined,
  TtyOutlined,
  LoginOutlined,
} from '@mui/icons-material';
import logo from 'src/components/logo';
import Logo from 'src/components/logo';

export default function Menu() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    console.log('new open', newOpen);
    setOpen(newOpen);
  };

  const menu = [
    { label: 'Home', url: '/', icon: <OtherHousesOutlined /> },
    { label: 'About Us', url: '/about_us', icon: <GroupsOutlined /> },
    { label: 'Products', url: '/products', icon: <Inventory2Outlined /> },
    { label: 'For Membership', url: '/auth/register', icon: <HowToRegOutlined /> },
    { label: 'Legal', url: '/legal', icon: <PolicyOutlined /> },
    { label: 'Gallery', url: '/gallery', icon: <CollectionsOutlined /> },
    { label: 'Contact us', url: '/contactus', icon: <TtyOutlined /> },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#A4548C',
          display: 'flex',
          justifyContent: {
            xs: 'space-between',
            md: 'center',
          },
          padding: '16px 0px',
        }}
      >
        {/* menu for small screen  */}
        <IconButton
          sx={{
            display: {
              md: 'none',
            },
          }}
          onClick={toggleDrawer(true)}
          aria-label="fingerprint"
          color="secondary"
        >
          <MenuIcon
            sx={{
              color: '#fff',
            }}
          />
        </IconButton>
        <NavButtons
          smallScreen={'inherit'}
          bigscreen={'none'}
          fontSize="8px"
          fontColor="#fff"
          iconSize="12px"
          iconColor="#fff"
          itemsGap="12px"
          marginRight="20px"
        />
        <Drawer
          sx={{
            backgroundColor: 'transparent',
            '& .MuiDrawer-paper': {
              backgroundColor: '#e09be800',
              minWidth: 220,
              // paddingLeft: 2,
            },
          }}
          open={open}
          onClose={toggleDrawer(false)}
        >
          <DrawerMenu menuitems={menu} drawerToggle={toggleDrawer} />
        </Drawer>

        {/* menu for big screen  */}
        <Box
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            gap: '32px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MenuItems menuitems={menu} />
        </Box>
      </Box>
    </>
  );
}

const MenuItems = ({ menuitems }) => {
  const navigate = useNavigate();

  var menuStyle = {
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '400',
    // textDecoration: 'none!important',
  };
  return (
    <>
      {menuitems &&
        menuitems.map((menuItem , index) => {
          return (
            <span key={index} onClick={() => navigate(menuItem.url)} className="clickable" style={menuStyle}>
              {menuItem.label}
            </span>
          );
        })}
    </>
  );
};

const DrawerMenu = ({ menuitems, drawerToggle }) => {
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <>
      <Box
        sx={{
          // backgroundImage: 'linear-gradient(167deg, #e3e3e3, #ed8eba)',
          backgroundColor: '#fff',
          py: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid black',
          borderBottomRightRadius: '10px',
          borderBottomLeftRadius: '10px',
        }}
      >
        <Logo />
      </Box>
      <MenuList
        sx={{
          p: 1,
          // marginTop: '20px',
          justifyContent: 'space-between!important',
          height: '100%',
        }}
      >
        {menuitems &&
          menuitems.map((menuitem, index) => {
            return (
              <MenuItem
                sx={{
                  backgroundColor: '#919eab29',
                  '&.Mui-selected': {
                    backgroundImage: 'linear-gradient(63deg, #f15858, #625090)',
                    color: 'white', // or any other color you want
                  },
                }}
                as={Link}
                to={menuitem.url}
                selected={selectedItem == index ? true : false}
                onClick={(drawerToggle(false), () => setSelectedItem(index))}
              >
                <ListItemIcon sx={{ color: '#fff' }}> {menuitem.icon} </ListItemIcon>
                <ListItemText sx={{ color: '#fff' }}> {menuitem.label} </ListItemText>
              </MenuItem>
            );
          })}

        <MenuItem
          sx={{
            // position: 'absolute',
            backgroundColor: '#919eab29',
            // bottom: 20,
            width: '100%',
          }}
          as={Link}
          to={'/auth/login'}
          onClick={drawerToggle(false)}
        >
          <ListItemIcon sx={{ color: '#fff' }}> {<LoginOutlined />} </ListItemIcon>
          <ListItemText sx={{ color: '#fff' }}> Login </ListItemText>
        </MenuItem>
      </MenuList>
    </>
  );
};
