/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

// ----------------------------------------------------------------------

import Router from 'src/routes/sections';

import ThemeProvider from 'src/theme';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, SettingsProvider, useSettingsContext } from 'src/components/settings';

// import { AuthProvider } from 'src/auth/context/jwt';
import { AuthContext } from 'src/utils/context/AuthContext';

import { useEffect, useMemo, useReducer } from 'react';
import { LoadingScreen } from './components/loading-screen';
import { send_get, send_post } from './utils/hooks/AxiosHook';


import { Auth, logsApi, userApis } from './utils/Apis/Apis';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, useTheme } from '@mui/material';
import { useLocalStorage } from './hooks/use-local-storage';

// ----------------------------------------------------------------------

export default function App() {
  var url =
    process.env.NODE_ENV == 'development' ? 'http://localhost:5004' : process.env.REACT_APP_API_URL;
  // console.log(process.env.NODE_ENV);
  var socketURL =
    process.env.NODE_ENV == 'development'
      ? 'http://localhost:5004'
      : process.env.REACT_APP_SOCKET_URL;
  // var userinfo = localStorage.getItem("userName");
  var userData = localStorage.getItem('userData');

  userData = JSON.parse(userData);
  const charAt = `
  ▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
  ████ ▄▄▀██████ ▄▄▄ ██
  ████ ████▀ ▀██▄▄▄▀▀██
  ████ ▀▀▄██▄███ ▀▀▀ ██
  ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  `;

  // console.info(`%c${charAt}`, 'color: #facc15');
  const theme = useTheme();
  useScrollToTop();

  const initialstates = {
    noImage :"https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/832px-No-Image-Placeholder.svg.png",
    socketURL,
    userInfo: { 
      email :userData?.email,
      profilePic : process.env.REACT_APP_ASSETS_API+userData?.image,
      mobile :userData?.mobile,
      userName: userData?.name , 
      title:userData?.title,
      type : userData?.type,
      status: userData?.status,
      userId: userData?.userId,
      memberID : userData?.memberID,
    
    },
    authenticated: true,
    authenticatedUser: 'Pending',
    authenticatedUserType: 'None',
    map_api: 'AIzaSyB7Dh6RfizKiaI61A5ImO9lGgfSqxci93c',
    Local_ip_url: url,


    // admin specific states 
    productStock : [],
    vendors : [],
    purchaseLogs : [],


    // general states

    mainSiteProductsList : [],

    // general states

    membersList: [],
    policiesList: [],
    kycList: [],
    productmastersList: [],
    productsList: [],
    featuredProducts: [],
    gallery: [],

    // member oriented states
    myDirects: [],
    kycRequests : [],
    userBanks : [],
    fundsHistory : [],
    ticketsHistory : [],
    cartContents : [],
    checkoutActiveStep : 0,
  };

  const appReducer = (prevState, action) => {
    // console.log(action);
    switch (action.type) {
      case 'AuthState': {
        return {
          ...prevState,
          auth_status: action.loggedIn,
          userId: action.userId,
          userMode: action.userType,
        };
      }

      case 'authenticate': {
        return {
          ...prevState,
          authenticated: action.data,
          authenticatedUser: action.validUser,
          authenticatedUserType: action.userType,
        };
      }

      case 'savedata': {
        return { ...prevState, [action.listType]: action.data };
      }
      case 'saveFiltred': {
        return { ...prevState, [action.listType]: action.data };
      }
      case 'removeData': {
        return { ...prevState, [action.listType]: action.data };
      }
    }
  };


  const [appStates, dispatch] = useReducer(appReducer, initialstates);


  const getCartContents = async() => {

    send_get(userApis.getCartContents).then((res)=>{
      var response = res.data;
      dispatch({
        type: 'savedata',
        listType: 'cartContents',
        data: response.data,
      })

    }).catch((err)=>[
      // toast.error(err.response.data.message)
    ])
  }

  const AppFunctions = useMemo(
    () => ({
      authStatus: (data) => {
        // console.log('ln. 104 app.js :', data);
        dispatch({
          type: 'authenticate',
          data: data.isValidUser,
          validUser: data.isValidUser,
          userType: data.userType,
        });
      },
      getData: (data) => {
        console.log(data)
        send_get(data.api)
          .then((res) => {
            var response = res.data;
            if (response.success) {
              dispatch({
                type: 'savedata',
                listType: data.listType,
                data: response.data,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              // Server responded with a status other than 2xx
              if (err.response.status === 404) {
                console.error('Resource not found (404):', err.response.data);
              } else {
                console.error(`Server error (${err.response.status}):`, err.response.data);
              }
            } else if (err.request) {
              // Request was made but no response was received
              console.error('No response received:', err.request);
            } else {
              // Something else caused the error
              console.error('Error setting up request:', err.message);
            }
    
            
            send_post(logsApi.senderror, {
              error: err,
            });
          });
      },

      updateState: (data) => {
        dispatch({
          type: 'savedata',
          listType: data.state,
          data: data.data,
        });
      },

      addToCart : ({states ,  productId , qty =1 , colour = '#ffffff' , size = 0})=>{
      
        if(states.authenticatedUser === true && states.authenticatedUserType == "Member"){
         

          send_post(userApis.addToCart , {productId ,  qty , colour , size }).then((res)=>{
            var response = res.data;

            if(response.success){
              toast(response.message)

              getCartContents();

            }else{
              toast.error(response.message)
            }
          }).catch((err)=>{
            // console.log(err)
            toast.error(err.response.data.message)
          })

          

        }else{
          toast.error('Login first')
        }
      },

      addToWishlist : () => {
        console.log('appStates' , appStates)
        if(appStates.authenticatedUser === true && appStates.authenticatedUserType == "Member"){
          console.log('Buy Now')
        }else{
          console.log('Buy Now')
        }
      },

      getCartData : () => {
        getCartContents()
      },

      logout: () => {
        dispatch({ 
           type: 'authenticate',
           data : false,
           validUser: false,
           userType: 'None'
           });
        AppFunctions.authStatus({
          data: false,
          validUser: false,
          userType: 'None',
        });
        localStorage.clear();
      },

      showToast: (data) => {

        let toastObj = {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: theme.palette.mode,
          // transition: Bounce,
        }

        if (data.type) {
          toast[data.type](data.msg, toastObj);
        } else {
          toast(data.msg, toastObj);
        }
      }
    }),
    []
  );


  // console.log('ln. 147 : ', appStates);

  useEffect(() => {

    

    if (localStorage.getItem('userData') && localStorage.getItem('token')  ) {
      var userData = JSON.parse(localStorage.getItem('userData'));
      const token = localStorage.getItem('token');

      const verifyToken = async (token) => {
        try {
          // Call backend to verify the token
          const response = await send_post(Auth.verifyToken, { token });
          if (response.data.success) {
            // Update the state if token is valid
            AppFunctions.authStatus({
              isValidUser: true,
              userType: userData.type,
            });
          } else {
            // If token is invalid, reset the authentication state
            AppFunctions.authStatus({
              isValidUser: false,
              userType: 'None',
            });
            localStorage.clear(); // Clear localStorage if invalid
          }
        } catch (error) {
          send_post(logsApi.senderror, {
            error,
          });
          AppFunctions.authStatus({
            isValidUser: false,
            userType: 'None',
          });
          localStorage.clear();
        }
      };

      // AppFunctions.authStatus({
      //   isValidUser: true,
      //   userType: userData.type,
      // });

      if(token){
        verifyToken(token);
      }else{
        AppFunctions.authStatus({
          isValidUser: false,
          userType: 'None',
        });
        localStorage.clear(); // Clear localStorage if invalid data
      }
    } else {
      console.log('UserData & Token Not Available')
      AppFunctions.authStatus({
        isValidUser: false,
        userType: 'None',
      });
    }
    // HandleGetPermissionList();
  }, []);

  // console.log(theme)
  if (appStates.authenticatedUser == 'Pending') {
    return <LoadingScreen />;
  }



  return (
    <>

      {/* <Button variant='contained' onClick={() => AppFunctions.showToast({ type2: 'error', msg: 'Hello test Msg' })} >Check Toast {theme.palette.mode}</Button> */}

      <AuthContext.Provider value={{ appStates, AppFunctions }}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', //'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: true,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SettingsDrawer />
              <ProgressBar />
              <Router />
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </AuthContext.Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
