import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const memberSidebarPaths = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: 'Dashboard',
            path: paths.userDashboard.root,
            icon: <Iconify icon="ant-design:dashboard-outlined" width={22} />,
          },
        ],
      },
      {
        items: [
          {
            title: 'Policy',
            path: paths.userDashboard.policy.root,
            icon: <Iconify icon="carbon:policy" width={22} />,
            children: [
              {
                title: 'Privacy Policy',
                path: paths.userDashboard.policy.privacyPolicy,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
              {
                title: 'Refund Policy',
                path: paths.userDashboard.policy.refundPolicy,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
              {
                title: 'Bussiness Plan',
                path: paths.userDashboard.policy.bussinessPlan,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
              {
                title: 'Promotions Of Distributers',
                path: paths.userDashboard.policy.promotions,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
              {
                title: 'Terms & Conditions',
                path: paths.userDashboard.policy.termsconditions,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            title: 'New Registration',
            path: paths.userDashboard.newRegistration,
            icon: <Iconify icon="icons8:add-user" width={22} />,
          },
        ],
      },

      {
        items: [
          {
            title: 'Profile',
            path: paths.userDashboard.profile.root,
            icon: <Iconify icon="iconamoon:profile-circle-light" width={22} />,
            children: [
              {
                title: 'Profile',
                path: paths.userDashboard.profile.root,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
              {
                title: 'Change Passwords',
                path: paths.userDashboard.profile.changePassword,
              },
              {
                title: 'Kyc',
                path: paths.userDashboard.profile.kyc,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
              {
                title: 'Bank Details',
                path: paths.userDashboard.profile.banks,
              },
              {
                title: 'Welcome Letter',
                path: paths.userDashboard.profile.welcomeLetter,
              },
              {
                title: 'ID Card',
                path: paths.userDashboard.profile.idCard,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            title: 'Funds Managment',
            path: paths.userDashboard.funds.root,
            icon: <Iconify icon="ri:funds-line" width={22} />,
            children: [
              {
                title: 'Add Funds',
                path: paths.userDashboard.funds.addFund,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
              {
                title: 'Requested Funds',
                path: paths.userDashboard.funds.requestFund,
              },
              {
                title: 'Funds History',
                path: paths.userDashboard.funds.fundHistory,
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            title: 'My Network',
            path: paths.userDashboard.network.root,
            icon: <Iconify icon="carbon:network-4" width={22} />,
            children: [
              {
                title: 'My Directs',
                path: paths.userDashboard.network.myDirects,
                // icon: <Iconify icon="solar:user-bold" width={22} />,
              },
              {
                title: 'Level Report',
                path: paths.userDashboard.network.levelReport,
              },
              {
                title: 'Tree View',
                path: paths.userDashboard.network.treeView,
              },
            ],
          },
        ],
      },

      {
        items: [
          {
            title: 'Licence Allotment',
            path: paths.userDashboard.licenceAllotment,
            icon: <Iconify icon="solar:card-2-line-duotone" width={22} />,
          },
          // solar:gallery-bold
          {
            title: 'My Income',
            path: paths.userDashboard.myIncome,
            icon: <Iconify icon="fluent-mdl2:money" width={22} />,
          },
          // ant-design:setting-filled
          {
            title: 'Order List',
            path: paths.userDashboard.orderList,
            icon: <Iconify icon="solar:cart-line-duotone" width={22} />,
          },
          {
            title: 'Shopping',
            path: paths.mainSite.shopping,
            icon: <Iconify icon="system-uicons:cart" width={22} />,
          },
          // {
          //   title: 'WithDrawal',
          //   path: paths.userDashboard.withdrawl,
          //   icon: <Iconify icon="uil:money-withdraw" width={22} />,
          // },
        ],
      },

      // {
      //   items: [
      //     {
      //       title: 'Support',
      //       path: paths.userDashboard.tickets.root,
      //       icon: <Iconify icon="fluent:person-support-28-regular" width={22} />,
      //       children: [
      //         {
      //           title: 'New Ticket',
      //           path: paths.userDashboard.tickets.newTicket,
      //           // icon: <Iconify icon="solar:user-bold" width={22} />,
      //         },
      //         {
      //           title: 'Ticket Report',
      //           path: paths.userDashboard.tickets.ticketReport,
      //           // icon: <Iconify icon="solar:user-bold" width={22} />,
      //         },
      //         {
      //           title: 'Ticket Completed',
      //           path: paths.userDashboard.tickets.myTickets,
      //           // icon: <Iconify icon="solar:user-bold" width={22} />,
      //         },
      //       ],
      //     },
      //   ],
      // },

      {
        items: [
          {
            title: 'Support',
            path: paths.userDashboard.tickets.newTicket,
            icon: <Iconify icon="fluent:person-support-28-regular" width={22} />,
            // children: [
            // {
            //   title: 'New Ticket',
            //   path: paths.userDashboard.tickets.newTicket,
            // },
            // {
            //   title: 'Ticket Report',
            //   path: paths.userDashboard.tickets.newTicket,
            // },
            // {
            //   title: 'Ticket Completed',
            //   path: paths.userDashboard.tickets.ticketCompleted,
            // },
            // ],
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      // {
      //   subheader: 'management',
      //   items: [
      //     {
      //       title: 'user',
      //       path: paths.dashboard.group.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: 'Orders', path: paths.dashboard.group.root },
      //         { title: 'Payouts', path: paths.dashboard.group.five },
      //         { title: 'Gallery', path: paths.dashboard.group.six },
      //         { title: 'Website Setting', path: paths.dashboard.group.six },
      //       ],
      //     },
      //   ],
      // },
    ],
    []
  );

  return memberSidebarPaths;
}
