// ----------------------------------------------------------------------

const ROOTS = {
  HOMEPAGE: "/",
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  MEMBER: '/member',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://codexsolutions.in/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    funds: `${ROOTS.DASHBOARD}/funds`,
    // members: `${ROOTS.DASHBOARD}/members`,
    // products: `${ROOTS.DASHBOARD}/products`,
    inventory: `${ROOTS.DASHBOARD}/inventory`,
    orders: `${ROOTS.DASHBOARD}/orders`,
    payouts: `${ROOTS.DASHBOARD}/payouts`,
    gallery: `${ROOTS.DASHBOARD}/gallery`,
    website_management: `${ROOTS.DASHBOARD}/website_management`,
    notifications: `${ROOTS.DASHBOARD}/notifications`,
    products: {
      root: `${ROOTS.DASHBOARD}/products`,
      productCatalog: `${ROOTS.DASHBOARD}/products`,
      productMasters: `${ROOTS.DASHBOARD}/products/masters`,
    },
    productInfo: `${ROOTS.DASHBOARD}/products/productInfo/:id`,

    members: {
      root: `${ROOTS.DASHBOARD}/members`,
      kycList: `${ROOTS.DASHBOARD}/members/kyc`,
    },

    // payouts: `${ROOTS.DASHBOARD}/payouts`,

    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
  },

  userDashboard: {
    root: ROOTS.MEMBER,


    policy: {
      root: `${ROOTS.MEMBER}/policy`,
      companyPolicy: `${ROOTS.MEMBER}/policy/companyPolicy`,
      privacyPolicy: `${ROOTS.MEMBER}/policy/privacyPolicy`,
      refundPolicy: `${ROOTS.MEMBER}/policy/refundPolicy`,
      bussinessPlan: `${ROOTS.MEMBER}/policy/bussinessPlan`,
      promotions: `${ROOTS.MEMBER}/policy/promotions`,
      termsconditions: `${ROOTS.MEMBER}/policy/termsconditions`,
    },


    newRegistration: `${ROOTS.MEMBER}/newRegistration`,

    profile: {
      root: `${ROOTS.MEMBER}/profile`,
      profile: `${ROOTS.MEMBER}/profile`,
      changePassword: `${ROOTS.MEMBER}/profile/changePassword`,
      kyc: `${ROOTS.MEMBER}/profile/kyc`,
      banks: `${ROOTS.MEMBER}/profile/banks`,
      idCard: `${ROOTS.MEMBER}/profile/idCard`,
      welcomeLetter: `${ROOTS.MEMBER}/profile/welcomeLetter`,
    },

    funds: {
      root: `${ROOTS.MEMBER}/funds`,
      fundManagement: `${ROOTS.MEMBER}/funds/fundManagement`,
      addFund: `${ROOTS.MEMBER}/funds/addFund`,
      requestFund: `${ROOTS.MEMBER}/funds/requestFund`,
      fundHistory: `${ROOTS.MEMBER}/funds/fundHistory`,
    },

    network: {
      root: `${ROOTS.MEMBER}/myNetwork`,
      myDirects: `${ROOTS.MEMBER}/myNetwork/myDirects`,
      levelReport: `${ROOTS.MEMBER}/myNetwork/treeDetails`,
      treeView: `${ROOTS.MEMBER}/myNetwork/generationTree`,
    },

    licenceAllotment: `${ROOTS.MEMBER}/licenceAllotment`,

    myIncome: `${ROOTS.MEMBER}/myIncome`,

    orderList: `${ROOTS.MEMBER}/orderList`,

    shopping: `${ROOTS.HOMEPAGE}products`,
    withdrawl: `${ROOTS.MEMBER}/withdrawl`,
    myAccount: `${ROOTS.MEMBER}/myAccount`,

    tickets: {
      root: `${ROOTS.MEMBER}/tickets`,
      newTicket: `${ROOTS.MEMBER}/tickets/raiseTicket`,
      ticketReport: `${ROOTS.MEMBER}/tickets/raisedTickets`,
      ticketCompleted: `${ROOTS.MEMBER}/tickets/ticketCompleted`,
    }
  },

  mainSite: {
    homepage: ROOTS.HOMEPAGE,
    shopping: `${ROOTS.HOMEPAGE}products`
  }


};
