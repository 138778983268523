import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/register'));
const ForgotPassword = lazy(() => import('src/pages/auth/forgotPassword'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      // <GuestGuard>
      <AuthClassicLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </AuthClassicLayout>
      // </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: <JwtLoginPage />,
      },
      {
        path: 'register',
        element: <JwtRegisterPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
    ],
  },
];
