import React from "react";
// import logo from "../assest/logo.svg";
import logo from "../assest/logo.svg"
import payment from "../assest/cards.svg";
import { Grid } from "@mui/material";

function Footer() {
  return (
    <div

    >
      {/* <img style={{ height: "56px", width: "245px" }} src={logo} /> */}


      <Grid sx={{ justifyContent: "center", p: 3  , borderTop:'1px solid lightgrey'}} container spacing={3}>

        {/* About Company */}
        <Grid item xs={12} md={3}>
          <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <div style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}>
              About Company
            </div>
            <div
              style={{
                // width: "270px",
                color: "#585858",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "21px",
              }}
            >
              Our common bond is what unites us and drives our success. We all
              want to prosper in life, so let Founding Fashion & Techno Worldwide
              Pvt. Ltd. lead you on the path to success. Founding Fashion & Techno
              Worldwide Pvt. Ltd.
            </div>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          {/* Help */}
          <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <div style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}>
              Help
            </div>
            <div
              style={{
                // width: "270px",
                color: "#585858",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                gap: "14px",
                lineHeight: "14px",
                flexDirection: "column",
              }}
            >
              <span>FAQ</span>
              <span>Contact Us</span>
              <span> Transparency</span>
            </div>
          </div>
        </Grid>

        <Grid item xs={6} md={3}>
          {/* Policy */}
          <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <div style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}>
              Policy
            </div>
            <div
              style={{
                // width: "270px",
                color: "#585858",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "14px",
                lineHeight: "16.8px",
              }}
            >
              <span>Return and Refund Policy</span>
              <span>License Transfer Policy</span>
              <span>Code Of Ethics And Conducts</span>
              <span>Direct Seller Contract</span>
              <span>Founding Terms And Conditions</span>
              <span>Privacy Policy</span>
              <span>Website T&C</span>
              <span>Unauthorized Distributor & False Information</span>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={3}>
          {/* Address */}
          <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <div style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}>
              Address
            </div>
            <div
              style={{
                // width: "270px",
                color: "#585858",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "16.8px",
                display: "flex",
                flexDirection: "column",
                gap: "14px",
              }}
            >
              <span>Address: Mall Godown Road, Panipat, Haryana, 132103</span>
              <span>Email: contact@dsfounding.in</span>
              <span>WhatApps: 7404309292</span>
              <span>Website: www.dsfounding.com</span>
            </div>
          </div>
        </Grid>

      </Grid>
      {/* </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginTop: "16px",
          gap: 12,
          padding: 10,
          marginBottom: "20px"
        }}
      >
        <div>
          <span
            style={{
              color: "#989898",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "14px",
            }}
          >
            Copyright © 2024 Founding Fashion & Techno Worldwide Pvt. Ltd. All
            Rights Reserved. |{" "}
          </span>
          <span
            style={{
              color: "#E45301",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "14px",
            }}
          >
            Privacy Policy | Terms & Conditions
          </span>
        </div>
        <img style={{ width: "318px", height: "28px" }} src={payment} />
      </div>
    </div>
  );
}

export default Footer;
